<template>
  <div class="account-merge-box">
    <div class="tips-box" v-if="step === 0">
      <div class="title">账号变更前，请确认以下内容</div>
      <div class="title-2">此功能仅适用于以下情况:</div>
      <div class="text">1. 手机号注册了一个账号A，微信号注册了另一个账号B，想要同时使用手机号和微信号登录其中一个账号的情况。</div>
      <div class="text">2. 当账号同时绑定了手机号和微信号，不支持进行此变更操作。</div>
      <div class="title-2 m-t-32">变更后手机号和微信号将登录同一个账号</div>
      <div class="text">1. 执行此操作后，将只会保留一个账号（A 或 B，二选一），手机号和微信号均可以登录该账号，而另一个账号将无法再进行登录且数据不支持找回。</div>
      <div class="text">2. 已下载或已购买的课程、资源，以及账号资产会自动合并至保留的账号中。</div>
      <div class="title-2 m-t-32">此操作不可逆，请谨慎操作</div>
      <div class="text">更多详细内容请查看 <a href="https://zhiku.bopuyun.com/pc/article/90511" target="_blank" title="《博普智库账号合并使用须知》">《博普智库账号合并使用须知》</a>，若有疑问，请联系博普智库工作人员BPY2021。</div>
      <div class="base-btn btn" @click="step++">确认变更</div>
    </div>
    <div class="step-box" v-if="step !== 0">
      <div class="head" style="justify-content: center; position: relative;">
        <img src="/images/icon-line-left.svg" style="position: absolute; left: 0;" @click="next"/>
        <div v-if="step === 1">使用手机登录账号</div>
        <div v-if="step === 2">使用微信号登录</div>
        <div v-if="step === 3">选择要保留的账号</div>
        <div v-if="step === 4">操作成功</div>
      </div>
      <div class="content"  v-if="step !== 4">
        <div class="item" :class="{'step-active': step === 1}">
          <div class="success-icon" v-if="step > 1">
            <img src="/images/icon-check.svg"/>
          </div>
          <span v-else>1</span>
          <div class="step-text">
            <div class="flex-center">
              <div class="title">第一步</div>
              <div class="line"/>
            </div>
            <div class="tips">使用手机号登录账号</div>
          </div>
        </div>
        <div class="item" :class="{'step-active': step === 2}">
          <div class="success-icon" v-if="step > 2">
            <img src="/images/icon-check.svg"/>
          </div>
          <span v-else>2</span>
          <div class="step-text">
            <div class="flex-center">
              <div class="title">第二步</div>
              <div class="line"/>
            </div>
            <div class="tips">使用微信登录帐号</div>
          </div>
        </div>
        <div class="item" :class="{'step-active': step === 3}">
          <div class="success-icon" v-if="step > 3">
            <img src="/images/icon-check.svg"/>
          </div>
          <span v-else>3</span>
          <div class="step-text">
            <div class="flex-center">
              <div class="title">第三步</div>
              <div class="line"/>
            </div>
            <div class="tips">选择要保留的账号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="verification-box" v-if="step === 1">
      <a-form-model ref="form" :model="form" :rules="pwd_rules">
        <a-form-model-item label="" prop="my_account">
          <a-input v-model="form.my_account"  placeholder="请输入手机号" @change="mobileChange"/>
        </a-form-model-item>
        <a-form-model-item class="code-box" label="" prop="code">
          <a-input class="code-input" v-model="form.code" placeholder="短信验证码" @change="smsChange">
            <div slot="addonAfter" class="code-btn" :class="{'active': can_send_code === true}" @click="sendCode('modify_account')">{{send_code_text}}</div>
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <div class="base-btn" @click="verifySmsCode('form')" :class="{ 'disabled': form.my_account === '' || form.code === '' }">下一步</div>
    </div>
    <div class="wx-code" v-show="step === 2">
      <div id="login_container"></div>
      <div class="content">
        <div class="text">使用微信 扫一扫 扫码</div>
        <div class="wx-err-tips">
          <div v-show="showWxTips">
            {{wxTipsText}}
          </div>
        </div>
        <div class="base-btn btn" @click="wxLoginNext">下一步</div>
      </div>
    </div>
    <div class="select-account" v-if="step === 3">
      <div class="user-item" @click="checkItem = userData.phone_user_info.uid" :class="{ 'active': checkItem === userData.phone_user_info.nickname }">
        <div class="content">
          <div class="flex-center">
            <div class="icon">
              <img src="/images/icon-phone.svg"/>
            </div>
            <p class="name">{{userData.phone_user_info.nickname}}</p>
          </div>
          <p class="phone">绑定手机号：{{userData.phone_user_info.phone}}</p>
        </div>
        <div>
          <img src="/images/invalid-name.svg" v-if="checkItem === userData.phone_user_info.uid"/>
          <div class="clicle" v-else/>
        </div>
      </div>
      <div class="user-item"  @click="checkItem = userData.wechat_user_info.uid" :class="{ 'active': checkItem === userData.wechat_user_info.uid }">
        <div class="content">
          <div class="flex-center">
            <div class="icon">
              <img src="/images/icon-wx.svg"/>
            </div>
            <p class="name">{{userData.wechat_user_info.nickname}}</p>
          </div>
          <p class="phone">绑定微信号：{{userData.wechat_user_info.wechat_name}}</p>
        </div>
        <div>
          <img src="/images/invalid-name.svg" v-if="checkItem === userData.wechat_user_info.uid"/>
          <div class="clicle" v-else/>
        </div>
      </div>
      <div class="base-btn btn" @click="affirmModel = true;affirmInput = ''">确认变更</div>
    </div>
    <div class="success" v-if="step === 4">
      <div class="head">
        <img src="/images/1-icon-2-1-success.svg"/>
        <p>你已操作成功</p>
      </div>
      <div class="text">
        <p>现在可使用手机号「{{userData.phone_user_info.phone}}」或者微信号「{{userData.wechat_user_info.nickname}}」 登录账号「{{checkItem == userData.wechat_user_info.uid ? userData.wechat_user_info.nickname : userData.phone_user_info.nickname}}」</p>
        <p class="tips">若有其他疑问，请联系客服，客服微信号：BPYD2021</p>
      </div>
      <div class="base-btn" @click="back">进入个人账号中心</div>
    </div>
    <!-- 确认dialog -->
    <a-modal v-model="affirmModel" title="选择要保留的账号" width="560px" :footer="null">
      <div class="dialog-box">
        <div>
          执行操作后，你将无法再登录「{{checkItem == userData.wechat_user_info.uid ? userData.phone_user_info.nickname : userData.wechat_user_info.nickname}}」，该账号中的 <span>数据不支持找回或恢复</span>，请谨慎操作。
        </div>
        <div class="tips">
          请在下方输入“<span>确认变更</span>”确保你知悉操作结果
        </div>
        <input type="text" v-model="affirmInput">
        <div class="footer">
          <button class="cancel btn" @click="handleCancel">取消</button>
          <button class="ok btn" @click="handleOk" :class="{ active: affirmInput === '确认变更' }">确认</button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Util from '@/assets/util.js'
const phone_reg = /^[1][0-90]{10}$/
export default {
  data () {
    const validateMobile = async(rule, value, callback) => {
      this.mobile_check = false
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!new RegExp(phone_reg).test(value)){
        callback(new Error('请输入正确的11位手机号'))
      } else {
        let res = await MergeService.checkPhoneBindStatus({
          phone: this.form.my_account
        })
        if (res.status === 1) {
          this.mobile_check = true
          callback()
        } else {
          const text = res.status === 2 ? '该手机已注册账号且已绑定微信号，请换一个手机号' : '该手机号未注册'
          callback(text)
        }
        
      }
    }
    const validateMsgVerification2 = (rule, value, callback) => {
      if (new RegExp(/^$| /).test(this.form.code)) {
        callback(new Error('请输入验证码'))
      } else if (this.failure_code && this.failure_code == 505) {
        callback(new Error('验证码不正确'))
      } else {
        callback()
      }
    }
    const validatePwd = async(rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if(!Util.validatePwd(value)) {
        callback(new Error('密码为6~20位，数字、大写字母和小写字母两种以上组合'));
      } else {
        callback()
      }
    }
    const validatePwd2 = async(rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入重置密码'))
      } else if(value != this.form.password) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }
    return {
      step: 0,
      code_count: '',
      failure_code: '', // 登录错误码,
      form: {
        my_account: '',
        account: '',
        code: '',
        password: '',
        confirm_password: ''
      },
      mobile_check: false,
      pwd_rules: {
        my_account: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        code: [
          { validator: validateMsgVerification2, trigger: 'blur' }
        ],
        password: [
          { validator: validatePwd, trigger: 'blur' }
        ],
        confirm_password: [
          { validator: validatePwd2, trigger: 'blur' }
        ]
      },
      value: 1,
      state: '', //手机code
      code: '', // wxcode
      userData: {
        phone_user_info: {
          uid: 1
        },
        wechat_user_info: {
          uid: 1
        }
      },
      checkItem: 1, //选择
      affirmModel: false, // 确认model
      affirmInput: '',
      showWxTips: false,
      wxTipsText: ''
    }
  },
  computed: {
    can_send_code() {
      return !this.code_status && this.mobile_check
    },
    code_status() {
      return this.code_count > 0
    },
    send_code_text() {
      if (this.code_status) {
        return `${this.code_count}S`
      }
      return '获取验证码'
    },
  },
  watch: {
    $route: {
      handler (val) {
        if (val.query.code) {
          this.state = val.query.state
          this.code = val.query.code
          this.getWechatUserAndPhoneUserInfo()
        }
      },
      immediate: true,
      deep: true
    },
    step: {
      handler (val) {
        if (Number(val) !== 2) {
          this.showWxTips = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    next () {
      this.step--
      if (this.state) {
        this.createWxQrcode()
      }
    },
    handleCancel () {
      this.affirmInput = ''
      this.affirmModel = false
    },
    async handleOk () {
      if (this.affirmInput === '确认变更') {
        try {
          const params = {
            chose_uid: this.checkItem,
            drop_uid: this.checkItem == this.userData.wechat_user_info.uid ? this.userData.phone_user_info.uid : this.userData.wechat_user_info.uid
          }
          const res = await MergeService.mergeAccount({ msg: Util.encryptData(params).d })
          if (res) {
            this.step++
            this.affirmModel = false
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    async getWechatUserAndPhoneUserInfo () {
      const params = {
        code: this.code,
        state: this.state
      }
      const { data } = await MergeService.getWechatUserAndPhoneUserInfo(params)
      if (data.phone_user_info.uid === data.wechat_user_info.uid) {
        this.message.error('帐号重复，请更换账号！')
        this.step = 1
      } else if (data.wechat_user_info.status === 0 || data.wechat_user_info.status === 2) {
        this.step = 2
        this.showWxTips = true
        this.wxTipsText = data.wechat_user_info.status === 2 ? '该微信已注册账号且已绑定手机号，请换一个微信扫码' : '该微信未注册'
        this.createWxQrcode()
      } else {
        this.userData = data
        this.checkItem = this.userData.phone_user_info.uid
        this.step = 3
      }
    },
    wxLoginNext () {
      if (!this.showWxTips) {
        this.step = 3
      }
    },
    setIframeAttr () {
      document.getElementsByTagName('iframe')[0].setAttribute('sandbox', 'allow-scripts allow-top-navigation')
    },
    createWxQrcode() {
      // 生成微信登录二维码
      var obj = new WxLogin({
        id:"login_container", 
        appid: "wxbb5bcc7f8f50e511",
        scope: "snsapi_login",
        redirect_uri: encodeURI("https://user.bopuyun.com/bpy_account/change"),
        state: this.state,
        style: "white",
        href: encodeURI("https://user.bopuyun.com/bpy_account/change")
      })
      this.setIframeAttr()
    },
    verifySmsCode (name) { 
      this.$refs[name].validate(async (valid) => {
        if (this.form.my_account === '' || this.form.code === '') {
          return
        }
        let param = {
          account: this.form.my_account,
          code: this.form.code
        }
        try {
          let res = await AuthService.verifySmsCode(param)
          if (res) {
            const res2 = await MergeService.createVerifyPhoneAccountSign(param)
            this.state = res2
            this.step++
            this.createWxQrcode()
          }
        } catch(e) {
          this.$message.error('验证码错误')
        }
      })
    },
    mobileChange() {
      this.failure_code = false
    },
    smsChange() {
      this.failure_code = false
    },
    async sendCode(key) {
      if(!this.can_send_code) return
      let param = {
        account: this.form.my_account,
        sms_type: key || 'modify_account'
      }
      let res = await AuthService.sendSmsCode(param)
      if (res) {
        this.code_count = 60
        let timer = setInterval(() => {
          this.code_count--
          if(this.code_count <= 0) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    back () {
      this.$router.push({
        path: '/auth_login',
        query: this.$route.query
      })
    }
  }
}
</script>

